
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Newsletter</h5>
      <div class="card">
        <div class="card-body">
          <div class="row gy-4 justify-content-between">
            <div class="col-md-6">
                <h4 class="mb-4">Email Notifications</h4>
                <!-- <div class="form-check mb-2 form-switch">
                    <input v-model="data.update_item" class="form-check-input" type="checkbox" role="switch" id="notfTaskUpdates">
                    <label class="form-check-label" for="notfTaskUpdates">Item updates</label>
                </div>
                <div class="form-check mb-2 form-switch">
                    <input v-model="data.finished_item" class="form-check-input" type="checkbox" role="switch" id="notfFinishedTask">
                    <label class="form-check-label" for="notfFinishedTask">Finished Items</label>
                </div>
                <div class="form-check form-switch">
                    <input v-model="data.promotions" class="form-check-input" type="checkbox" role="switch" id="notfPromotions">
                    <label class="form-check-label" for="notfPromotions">Promotions and Offers</label>
                </div> -->
            </div>
            <div class="col-md text-sm-end">
                <button @click="saveChanges()" class="btn btn-outline-primary">Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
      return {
        data:{
          promotions: true,
          finished_item: true,
          update_item: true
        }
      }
    },
    watch:{
      '$store.state.activeUser':function(){
        this.getNotification()
      }
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      }
    },
    methods:{
      getNotification(){
        if(!this.activeUser.notification) return
        this.data = {...this.activeUser.notification}
      },
      saveChanges(){
        // const formData = new FormData()
        // formData.append('data', JSON.stringify(this.data))
        // this.$store.dispatch("changeLoaderValue", true)
        // this.$http.post('/account/notification', formData)
        // .then((response) => {
        //   this.$store.dispatch("changeLoaderValue")
        //   if(response.data.success){
        //     this.$store.commit("UPDATE_NOTIFICATION",response.data.data)
        //   }
        // })
      }
    },
    mounted(){
      this.getNotification()
    }
}

</script>

<style lang="scss">

</style>
